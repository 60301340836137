import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Error from '../error';
import { ReactComponent as ExternalIcon } from 'components/assets/icons/external.svg';
import APP_CONSTANTS from 'constants/app';

const NotFound: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const button = React.useMemo(
    () => ({
      text: formatMessage({ id: 'GO_BACK' }),
      onClick: () => {
        navigate(-1);
      }
    }),
    [formatMessage, navigate]
  );

  return (
    <Error
      title={<FormattedMessage id={'ERROR_NOT_FOUND_HEADING'} />}
      description={
        <React.Fragment>
          <p className="my-4">
            <FormattedMessage id={'ERROR_NOT_FOUND_MESSAGE'} />
          </p>
          <p className="mb-5">
            <a
              target={'_blank'}
              href={APP_CONSTANTS.XERO_HELP_CENTRAL_400_500_URL}
              className="inline-flex items-center underline"
              rel="noreferrer">
              <FormattedMessage id={'ERROR_LEARN_OTHER_WAYS'} />
              <span className="ml-1">
                <ExternalIcon />
              </span>
            </a>
          </p>
        </React.Fragment>
      }
      button={button}
    />
  );
};

export default NotFound;
