import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { Home, NotFound } from 'components/pages';

const Root = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const router = createBrowserRouter([
  { path: '/', Component: Home },
  { path: '*', Component: Root }
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
