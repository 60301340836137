import React from 'react';
import { ReactComponent as SunLogo } from 'components/assets/logos/sun.svg';
import { ReactComponent as CloudLogo } from 'components/assets/logos/cloud.svg';

const Header: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="md:flex">
      <div className="md:flex-1 md:flex justify-end">
        <SunLogo />
      </div>
      <div className="flex items-center justify-center md:w-96 md:mx-10 py-4">
        <h1 className="text-3xl text-blue-900 font-sans font-extrabold text-center align-middle">
          {children}
        </h1>
      </div>
      <div className="md:flex-1 flex md:items-end justify-end md:justify-start">
        <CloudLogo />
      </div>
    </div>
  );
};

export default Header;
