import { ButtonLink, Card, Header } from 'components/ui';
import { ReactComponent as PhoneLogo } from 'components/assets/logos/phone.svg';
import { FormattedMessage } from 'react-intl';
import APP_CONSTANTS from 'constants/app';

const Home = () => {
  return (
    <div className="container h-full mx-auto pt-10 md:py-24 flex flex-col justify-between md:justify-normal">
      <div className="w-full px-4">
        <Header>
          <FormattedMessage id={'ACTIVATION_ACTIVATED'} />
        </Header>
      </div>

      <div className="flex justify-center h-full mt-12">
        <Card className="h-full md:h-96 w-96 max-md:rounded-b-none">
          <div className="flex flex-col justify-around h-full">
            <div className="pt-4 font-sans text-blue-950 text-center tracking-tight">
              <span className="md:hidden">
                <FormattedMessage id={'ACTIVATION_NEXT_STEP_MOBILE'} />
              </span>
              <span className="hidden md:block">
                <FormattedMessage id={'ACTIVATION_NEXT_STEP_WEB'} />
              </span>
            </div>

            <div className="flex justify-center">
              <PhoneLogo />
            </div>

            <ButtonLink className="md:hidden" href={APP_CONSTANTS.DEEPLINK_URL}>
              <FormattedMessage id={'ACTIVATION_GET_STARTED'} />
            </ButtonLink>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Home;
