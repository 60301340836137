import { PropsWithChildren, createContext, useState } from 'react';

export type AppContextState = {
  errorId: string;
  loading: boolean;
  setErrorId: (id: string) => void;
  setLoading: (loading: boolean) => void;
};

const AppContext = createContext<AppContextState | null>(null);

const AppContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [errorId, setErrorId] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{
        errorId,
        loading,
        setErrorId,
        setLoading
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
